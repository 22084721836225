body {
  margin: 0px;
  padding: 0px;
}

.App {
  width: 1920px;
  height: 1080px;
}

header {
  width: 100%;
  height: 48px;
  justify-content: flex-end;
}

main {
  width: 100%;
  height: calc(100% - 48px);
  background-color: rgb(236, 239, 241);
}

.cn-main-content-infomation {
  display: flex;
  height: 88px;
  width: calc(100% - 24px - 24px);
  font-size: 24px;
  font-weight: bold;
  margin: 0px 24px;
  align-items: center;
}

.cn-main-display {
  height: calc(100% - 136px - 24px);
  width: calc(100% - 24px - 24px - 24px - 24px);
  padding: 24px 24px;
  margin: 0px 24px;
  border-radius: 10px;
  align-items: center;
  background-color: white;
}

/* light  上の末尾.がついてないものはいずれ無くす？ */
.cn-main-display.light {
  height: calc(100% - 136px - 24px);
  width: calc(100% - 24px - 24px - 24px - 24px);
  padding: 24px 24px;
  margin: 0px 24px;
  border-radius: 10px;
  align-items: center;
  background-color: white;
}

/* dark */
.cn-main-display.dark {
  height: calc(100% - 136px - 24px);
  width: calc(100% - 24px - 24px - 24px - 24px);
  padding: 24px 24px;
  margin: 0px 24px;
  border-radius: 10px;
  align-items: center;
  background-color: #2E2E2E;
}

.cn-setting-display {
  height: calc(100% - 136px - 5px);
  width: 100%;
  padding: 5px 5px;
  margin: 0px 5px;
  border-radius: 10px;
  align-items: center;
  /* background-color: white; */
}

/* dark */
/* .cn-setting-display.dark {
  background-color: #2E2E2E;
} */

.cn-sub-display.light {
  max-height: 450px;
  width: 1100px;
  align-items: center;
  background-color: white;
  z-index: 999;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.cn-sub-display.dark {
  max-height: 450px;
  width: 1100px;
  align-items: center;
  background-color: #2E2E2E;
  z-index: 999;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}


.cn-sub-display-account.light {
  width: 250px;
  align-items: center;
  background-color: white;
  z-index: 999;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.cn-sub-display-account.dark {
  width: 250px;
  align-items: center;
  background-color: #2E2E2E;
  z-index: 999;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.sideber {
  display: flex;
  justify-content: flex-start;
  width: 184px;
  height: calc(100% - 8px - 8px);
}
