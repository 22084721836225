
.settingPage {
    background-color: #f5f5f5;
    height: 80%;
    width: 100%;
}


.activeTab {

}

.inactiveTab {

}

.tabFrame {

}

