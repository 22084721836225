/* いらないかも　8行目まで */
:root {
  /* Light */
  --light-color-company: none;

  /* dark */
  --dark-color-company: brightness(0) invert(1);
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: red;
} */
.Mode.light {
  background-color: #cfd8dc;
  color: #121212;
}

.Mode.dark {
  background-color: #121212;
  color: #ffffff;
}

/* 会社のロゴの色を反転 */
.company.dark {
  filter: brightness(0) invert(1);
}
.company.light {
  filter: none;
}

/* 
  矢口CSS
  .Mode.dark {
    background-color: black;
    color: #e1e1e1;
    transition: all 0.5s;


  }
  
  .Mode.light {
    background-color: #F8F3EF;
    color: #353535;
    transition: all 0.5s;
  }  */

.App.dark {
  background-color: #2e2e2e;
  color: #e1e1e1;
  transition: all 0.5s;
}

.App.light {
  background-color: #ffffff;
  color: #353535;
  transition: all 0.5s;
}
