
.parent {
    background: #FFFFFF;
    border-radius: 8px;
    height: 100%;
}
.commonButton{
    color: black;
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    width: 130px;
    height: 30px;
    background: #FFFFFF;

    margin-right: 0;
    margin-left: 90%;
}


.tableFrame {
    padding-top: 2%;
    padding-left: 2%;
    width: 96%;
    height: 600px;
}

.tableStyle {
    width: 100%;

}

.tableHeader {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    background-color: #FFFFFF;
    width: 100%;
}

.tableHeadItem {
    flex: none;
    order: 2;
    flex-grow: 0;
    border: 1px solid #0000001F;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
    width: 10%;
}

.tableBody {

}

